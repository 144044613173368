import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core';
interface Props {
    options: any;
    handleChange: any;
    placeHolder: string;
    noOptionsText?: any;
    id: string;
}

const CustomTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: '#A855F7', // Border color on hover
            },
            '&.Mui-focused fieldset': {
                borderColor: '#A855F7', // Border color when focused
            },
        },
        '& .MuiInputLabel-outlined.Mui-focused': {
            color: '#A855F7', // Floating label color when focused
        },
    },
})(TextField);

export default function SearchBar({
    options,
    handleChange,
    placeHolder,
    noOptionsText,
    id,
}: Props) {
    return (
        <div className="mt-0.5">
            <Autocomplete
                id={id.toLowerCase()}
                autoComplete
                clearOnBlur={false}
                clearOnEscape
                disableClearable
                options={options}
                openOnFocus={false}
                disableListWrap={true}
                noOptionsText={noOptionsText}
                onInputChange={(e, newVal, reason) => handleChange(e, newVal, reason)}
                onSelect={(e) => handleChange(e)}
                renderInput={(params) => (
                    <CustomTextField
                        id={`${id.toLowerCase()}-text`}
                        {...params}
                        placeholder={placeHolder}
                        variant="outlined"
                        label="Search"
                        margin="none"
                        size="small"
                    />
                )}
            />
        </div>
    );
}
